<template>
  <div>
    <b-alert show variant="warning" v-if="!isValidLea">
      Due to volume of data, student level reports are only available at the District, or School level. Please select an
      appropriate LEA from the LEA Context Picker above.
    </b-alert>
    <div v-else>
      <div class="report-container">
        <div class="row mb-2">
          <div class="col">
            <b-form-select :options="ayOptions" v-model="academicYear" @change="getData">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Academic Year --
                </b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>

        <Promised :promise="loadData" v-show="loadData">
          <template v-slot:pending>
            <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
              <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
            </div>
          </template>
          <template v-slot>
            <b-form>
              <b-form-row>
                <b-col>
                  <b-form-row>
                    <b-col cols="4">
                      <filter-item 
                        v-model="schoolLea"
                        label="School"
                        :options="schools" 
                      />
                    </b-col>
                    <b-col cols="4">
                      <filter-item 
                        v-model="grade"
                        label="Grade"
                        :options="careerCoachGrades" 
                      />
                    </b-col>
                    <b-col cols="4">
                      <filter-item 
                        v-model="recordedBy"
                        label="Recorded By"
                        :options="recorders" 
                      />
                    </b-col>
                  </b-form-row>
                </b-col>
              </b-form-row>
            </b-form>
            <div v-if="reportData" class="report-output">
              <button @click="exportExcel" class="btn btn-success float-right d-print-none mx-1">
                <i class="far fa-file-excel" />&nbsp;Excel
              </button>
              <paginated-table :items="filteredReportData" :fields="fields" sort-by="l" :sort-asc="true" :per-page="20" striped hover />
            </div>
          </template>
          <template v-slot:rejected="error" class="align-items-center justify-content-center">
            <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
          </template>
        </Promised>
      </div>
    </div>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable.vue'
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import { AYDependentReportMixin } from '../AYDependentReportMixin'
import ApiService from '@/services/ApiService'
import { formatDate } from '@/helpers/formatters'
import { gradeSpans } from '@/helpers/gradeSpans'
import FilterItem from '../studentListing/FilterItem.vue'
import { unique } from '@/helpers/globalHelpers.js'
import { LeaTypes } from '@/helpers/leaTypes'
import { mapState } from 'vuex'
import ExcelService from '@/services/ExcelService'
import { groupBy } from '@/helpers/globalHelpers'

export default {
  components: {
    Promised,
    CardStateIcon,
    PaginatedTable,
    FilterItem,
  },
  data() {
    return {
      academicYear: null,
      loadData: null,
      reportData: null,
      grade: null,
      recordedBy: null,
      showFilters: true,
      schoolLea: null,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    isAdmin() {
      return (this.userContext.roles || []).includes('Insights_CareerCoach_Admin')
    },
    isValidLea() {
      if (this.selectedLea.type == LeaTypes.State && this.isAdmin) {
        return true
      }
      return [LeaTypes.District, LeaTypes.School].includes(this.selectedLea.type)
    },
    schools() {
      return this.reportData
      .filter(i => i.schoolLEA && i.schoolLEA.length > 0)
      .map(i => i.schoolLEA)
      .filter(unique)
      .map(s => {
        return { text: this.leaFormatter(s), value: s }
      })
    },
    recorders() {
      return this.reportData.filter(i => i.lastUser && i.lastUser.length > 0).map(i => i.lastUser).filter(unique)
    },
    careerCoachGrades() {
      var grades = gradeSpans.filter(g=>g.value >= 7 && g.value <= 12);
      grades.push({value: 'G', text: 'Graduates / Previous AY - 12th Grade Students'})

      return grades
    },
    filteredReportData() {
      let items = this.reportData

      if (this.grade) 
        items = items.filter(i => i.grade == this.grade)

      if (this.recordedBy)
        items = items.filter(i => i.lastUser == this.recordedBy)

      if (this.schoolLea)
        items = items.filter(i => i.schoolLEA == this.schoolLea)

      return items
    },
    fields() {
      return [
        {
          key: 'districtLEA',
          label: 'District LEA',
          formatter: this.leaFormatter,
          sortable: true
        },
        {
          key: 'schoolLEA',
          label: 'School LEA',
          formatter: this.leaFormatter,
          sortable: true
        },
        {
          key: 'grade',
          sortable: true,
          formatter: (g) => g > 0 ? g : '',
        },
        {
          key: 'firstName',
          label: 'First Name',
          sortable: true
        },
        {
          key: 'lastName',
          label: 'Last Name',
          sortable: true
        },
        {
          key: 'name',
          label: 'Certificate Name',
          sortable: true
        },
        { key: 'lastUser', label: 'Last Updated By' },
        {
          key: 'date',
          formatter: (d) => formatDate(d, 'short-date'),
          sortable: true
        },
      ]
    }
  },
  methods: {
    async getData() {
      if (this.academicYear) {
        var payload = {
          lea: this.selectedLea.number,
          academicYear: this.academicYear
        }

        this.loadData = new ApiService().Get(`/api/cte/cc-reports/${payload.lea}/certifications?academicYear=${payload.academicYear}`)
          .then(d => this.reportData = d)
      }
    },
    async exportExcel() {
      const excelService = new ExcelService()
        const lea = this.selectedLea.number || ''
        const workbookName = `Certifications-${lea}`
        var workbook = await excelService.createWorkbook(workbookName)

        await excelService.addWorksheet(workbook, "Certifications", this.excelExportData().items)
        await excelService.addWorksheet(workbook, "Students By Certification", this.excelExportDataByCertifications().items)

        this.actionPromise = excelService.saveFile(workbook, `${workbookName}.xlsx`)
    },
    excelExportData() {
      return {
        name: 'Certifications',
        items: this.reportData.map(l => {
          return {
            District: this.leaFormatter(l.districtLEA),
            School: this.leaFormatter(l.schoolLEA),
            Grade: l.grade > 0 ? l.grade : '',
            "Student ID": l.studentId,
            "First Name": l.firstName,
            "Last Name": l.lastName,
            "Certificate Name": l.name,
            "Last Updated By": l.lastUser,
            "Date": formatDate(l.date, 'short-date')
          }
        })
      }
    },
    excelExportDataByCertifications() {
      const groupByCert = groupBy('name')
      const groups = groupByCert(this.reportData)
      return {
        name: 'By Certification',
        items: Object.keys(groups).flatMap(cert => {
          return [...groups[cert].map((l, index) => { 
            return {
              "Certification": index == 0 ? cert : "",
              District: this.leaFormatter(l.districtLEA),
              School: this.leaFormatter(l.schoolLEA),
              Grade: l.grade > 0 ? l.grade : '',
              "Student ID": l.studentId,
              "First Name": l.firstName,
              "Last Name": l.lastName,
              "Last Updated By": l.lastUser,
              "Date": formatDate(l.date, 'short-date')
            }
          }), {
              "Certification": "",
              District: "",
              School: "",
              Grade: "",
              "Student ID": "",
              "First Name": "",
              "Last Name": "",
              "Last Updated By": "",
              "Date": "",
            }]
        })
      }
    }
  },
  watch: {
    selectedLea: {
      immediate: false,
      handler() {
        this.getData()
      }
    },
  },
  mixins: [
    AYDependentReportMixin
  ]
}
</script>